export const COLORS = {
  white: '#FFFFFF',
  black: '#15181E',
  dark800: '#001F1B',
  dark700: '#172E2C',
  dark600: '#22494A',
  dark100: '#F6F6F3',
  primary50: '#ecf7f6',
  primary500: '#64AB8A',
  primary400: '#80BAA0',
  primary350: '#ADD2C1',
  primary300: '#CDE1D6',
  primary200: '#EAF2ED',
  secondary300: '#DFC566',
  secondary500: '#6C9D65',
  secondary600: '#55A580',
  elementsStatesHover: '#59997B',
  elementsStatesPressed: '#548F73',
  elementsStatesFocused: '#0077EA33',
  black40: '#1B252D5C',
  grayscale700: '#212731',
  grayscale600: '#394049',
  grayscale500: '#657281',
  grayscale400: '#8D98A5',
  grayscale200: '#E1E5EA',
  grayscale100: '#F0F2F5',
  systemError: '#F06060',
  systemSuccess: '#00B26B',
  green: '#3DAE9F',
  slate: '#5F6371',
  nativeBlack: '#000000',
  nativeBlack100: '#141414',
  nativeBlack200: '#1E1E1E',
  lightYellow: '#FFF495',
  lightYellow100: '#FDFF95',
  mint: '#3DAE9F',
  lightBlue: '#EEF9F9',
  gray: '#C2C2C2',
  lightGray: '#F7F7F7',
  paleGray: '#FAFAFA',

  // askona
  askona: {
    card: 'linear-gradient(180deg, #F5F6F7 0%, #EDEFEF 71.85%, #F5F6F6 100%)',
    dark: '#121818',
    grayscale400: '#617D7E',
  },
};
