import { FC, memo } from 'react';
import { COLORS } from '@landing/constants';

interface Props {
  color?: string;
}

const Arrow: FC<Props> = ({ color = COLORS.white, ...props }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.02246 12H20.0225M20.0225 12L14.0225 6M20.0225 12L14.0225 18"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

const Memo = memo(Arrow);
export default Memo;
