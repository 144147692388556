import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#ECF7F6"
        d="M0 10C0 4.477 4.477 0 10 0h20c5.523 0 10 4.477 10 10v20c0 5.523-4.477 10-10 10H10C4.477 40 0 35.523 0 30V10Z"
      />
      <path
        fill="#3DAE9F"
        d="M28.365 19.613h.004a.381.381 0 0 0 .38-.417 8.773 8.773 0 0 0-7.942-7.945.382.382 0 0 0-.417.385c.066 5.607.806 7.898 7.975 7.976Zm0 .774c-7.17.078-7.908 2.37-7.975 7.976a.382.382 0 0 0 .417.386 8.772 8.772 0 0 0 7.942-7.945.382.382 0 0 0-.384-.417Zm-9.196-9.134a8.777 8.777 0 0 0-7.917 7.943.382.382 0 0 0 .38.417h.005c7.145-.082 7.883-2.372 7.95-7.975a.383.383 0 0 0-.418-.385Zm-7.532 9.134c-.12-.013-.212.043-.285.123a.383.383 0 0 0-.1.293 8.777 8.777 0 0 0 7.953 7.945.384.384 0 0 0 .382-.386c-.067-5.603-.805-7.893-7.95-7.975Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Dzen = memo(SvgComponent);
export default Dzen;
