import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#ECF7F6"
        d="M0 10C0 4.477 4.477 0 10 0h20c5.523 0 10 4.477 10 10v20c0 5.523-4.477 10-10 10H10C4.477 40 0 35.523 0 30V10Z"
      />
      <path
        fill="#3DAE9F"
        fillRule="evenodd"
        d="M24.413 27.334a.892.892 0 0 0 .83.11.87.87 0 0 0 .544-.63c.651-3.064 2.232-10.817 2.826-13.603a.586.586 0 0 0-.195-.568.6.6 0 0 0-.598-.105c-3.145 1.164-12.83 4.797-16.788 6.262a.62.62 0 0 0-.407.6.621.621 0 0 0 .445.572c1.775.531 4.106 1.27 4.106 1.27s1.088 3.289 1.656 4.961c.071.21.236.375.452.432a.65.65 0 0 0 .609-.155l2.322-2.192s2.679 1.964 4.198 3.046Zm-8.257-6.507 1.259 4.153.28-2.63 7.639-6.89a.208.208 0 0 0 .024-.282.213.213 0 0 0-.282-.048l-8.92 5.697Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
const TelegramOutline = memo(SvgComponent);
export default TelegramOutline;
