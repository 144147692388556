import { FC, memo } from 'react';

const TherapistLogo: FC = ({ ...props }) => {
  return (
    <svg
      width={106}
      height={31}
      viewBox="0 0 106 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11091_195865)">
        <g clipPath="url(#clip1_11091_195865)">
          <path
            d="M101.515 2.36366C101.515 3.58898 100.521 4.57694 99.3021 4.57694C98.0768 4.57694 97.0889 3.58257 97.0889 2.36366C97.0889 1.14476 98.0768 0.150391 99.2957 0.150391C100.521 0.150391 101.515 1.13834 101.515 2.36366Z"
            fill="#B8362D"
          />
          <path
            d="M14.3959 12.564V26.6134H11.9837V14.7323H2.40573V26.6134H0V12.564H2.40573H11.9837H14.3959Z"
            fill="#1E1E1C"
          />
          <path
            d="M14.6011 21.4236C14.6011 17.7605 16.7053 15.4702 20.7277 15.4702C24.7693 15.4702 26.8479 17.7669 26.8479 21.4236C26.8479 25.0675 24.7629 27.3385 20.7277 27.3385C16.7053 27.3385 14.6075 25.0675 14.6011 21.4236ZM24.6859 21.4236C24.6859 18.7484 23.1911 17.4397 20.7277 17.4397C18.277 17.4397 16.7823 18.7484 16.7823 21.4236C16.7823 24.0603 18.277 25.369 20.7277 25.369C23.1911 25.369 24.6859 24.0603 24.6859 21.4236Z"
            fill="#1E1E1C"
          />
          <path
            d="M33.4556 21.4236C33.4556 17.7605 35.5534 15.4702 39.5822 15.4702C43.6238 15.4702 45.7023 17.7669 45.7023 21.4236C45.7023 25.0675 43.6174 27.3385 39.5822 27.3385C35.5534 27.3385 33.4556 25.0675 33.4556 21.4236ZM43.534 21.4236C43.534 18.7484 42.0392 17.4397 39.5757 17.4397C37.1251 17.4397 35.6368 18.7484 35.6368 21.4236C35.6368 24.0603 37.1315 25.369 39.5757 25.369C42.0392 25.369 43.534 24.0603 43.534 21.4236Z"
            fill="#1E1E1C"
          />
          <path
            d="M56.23 21.4236C56.23 17.7605 58.3278 15.4702 62.3502 15.4702C66.3918 15.4702 68.4703 17.7669 68.4703 21.4236C68.4703 25.0675 66.3918 27.3385 62.3502 27.3385C58.3278 27.3385 56.23 25.0675 56.23 21.4236ZM66.3148 21.4236C66.3148 18.7484 64.82 17.4397 62.3566 17.4397C59.9059 17.4397 58.4176 18.7484 58.4176 21.4236C58.4176 24.0603 59.9124 25.369 62.3566 25.369C64.82 25.369 66.3148 24.0603 66.3148 21.4236Z"
            fill="#1E1E1C"
          />
          <path
            d="M56.172 23.8226C56.172 25.7472 54.4784 26.7608 51.9572 26.7608H46.0103V16.0537H51.5146C53.869 16.0537 55.5626 17.016 55.5626 18.7738C55.5626 19.9991 54.6965 21.0961 53.1248 21.2052C54.7863 21.3142 56.172 22.0777 56.172 23.8226ZM48.1658 20.4482H50.9885C52.4255 20.4482 53.3878 20.2429 53.3878 19.0689C53.3878 17.8949 52.4255 17.8179 50.9885 17.8179H48.1658V20.4482ZM53.9395 23.6366C53.9395 22.4305 52.9772 22.1611 51.367 22.1611H48.1594V25.1121H51.367C52.9708 25.1057 53.9395 24.8426 53.9395 23.6366Z"
            fill="#1E1E1C"
          />
          <path
            d="M27.1304 26.7544V16.0537H31.4671H34.2578V17.8115H29.2859V22.6614V26.7544H27.1304Z"
            fill="#1E1E1C"
          />
          <path
            d="M71.0172 24.7272V30.5266H68.7397V15.9768H69.933L71.0172 17.8821C71.9346 16.5413 73.1791 15.5854 75.4565 15.5854C78.9208 15.5854 81.2175 17.8436 81.2175 21.3079C81.2175 24.7529 78.9208 27.0303 75.4565 27.0303C73.1791 27.0239 71.9281 26.0744 71.0172 24.7272ZM71.0172 20.8652V21.7634C71.261 24.214 73.1021 25.0801 74.8728 25.0801C77.2079 25.0801 78.9978 23.7393 78.9978 21.3079C78.9978 18.8765 77.0411 17.5164 74.8728 17.5164C73.1021 17.51 71.293 18.652 71.0172 20.8652Z"
            fill="#1E1E1C"
          />
          <path
            d="M93.4897 16.105V26.7608H92.072L91.2187 24.7015C90.2821 26.2155 88.5371 27.1521 86.3559 27.1521C83.1098 27.1521 81.4932 25.0735 81.4932 22.1931V16.105H83.7513V22.0327C83.7513 24.1305 84.8227 25.2596 86.9397 25.2596C88.7681 25.2596 90.3013 24.4063 91.2187 22.655V16.105H93.4897Z"
            fill="#1E1E1C"
          />
          <path
            d="M105.66 16.0923V26.7801H103.594V19.1588L98.5133 26.4786H98.4107L93.3233 19.0433V26.7801H92.1814L91.3218 24.708V16.0923H93.5415L98.5005 23.3672L103.459 16.0923H105.66Z"
            fill="#1E1E1C"
          />
          <path
            d="M98.6543 6.26416H99.7128V9.58728H104.659V6.26416H105.718V14.3602H104.659V10.5047H99.7128V14.3602H98.6543V6.26416Z"
            fill="#1E1E1C"
          />
          <path
            d="M68.4575 6.26416H69.516V9.58728H74.4558V6.26416H75.5207V14.3602H74.4558V10.5047H69.516V14.3602H68.4575V6.26416Z"
            fill="#1E1E1C"
          />
          <path
            d="M92.3544 14.2768C91.8732 14.4116 91.6615 14.4436 91.3793 14.4436C90.6864 14.4436 90.3207 13.9817 90.3207 13.0708C89.2622 14.2127 88.3641 14.5976 86.7731 14.5976C84.8164 14.5976 83.6104 13.6866 83.6104 12.2304C83.6104 11.2681 84.0402 10.6073 84.9383 10.2096C85.3553 10.0299 85.9134 9.8952 86.9591 9.76048L88.6463 9.52953C89.923 9.3499 90.3015 9.09971 90.3015 8.41969C90.3015 7.47664 89.4547 6.97625 87.9022 6.97625C86.0161 6.97625 85.15 7.5408 85.0217 8.88159H83.9439C84.0787 8.0027 84.239 7.57287 84.6368 7.14305C85.3168 6.34755 86.2919 6.0332 87.9471 6.0332C89.4226 6.0332 90.3656 6.3668 90.8981 7.09172C91.2638 7.57287 91.3279 7.9193 91.3279 9.17669V12.8206C91.3279 13.3017 91.4947 13.5327 91.8604 13.5327C91.9759 13.5327 92.1234 13.5006 92.3544 13.4364V14.2768ZM90.3143 10.5367C90.3143 10.2545 90.3336 10.139 90.3464 9.84388C89.9166 10.139 89.583 10.2609 88.4603 10.4405L86.9527 10.6715C85.1949 10.9537 84.6689 11.3002 84.6689 12.1598C84.6689 13.1349 85.4964 13.6995 86.9206 13.6995C88.0946 13.6995 89.1403 13.2696 89.8524 12.4741C90.1988 12.0572 90.3015 11.8454 90.3015 11.3835L90.3143 10.5367Z"
            fill="#1E1E1C"
          />
          <path
            d="M68.1304 10.3892C68.1304 12.8078 66.2892 14.5976 63.7873 14.5976C61.234 14.5976 59.4634 12.8591 59.4634 10.3571C59.4634 7.79101 61.2532 6.03322 63.8386 6.03322C66.3791 6.02681 68.1304 7.80384 68.1304 10.3892ZM60.5283 10.3058C60.5283 12.2625 61.9012 13.6546 63.8386 13.6546C65.6798 13.6546 67.0527 12.2496 67.0527 10.3571C67.0527 8.38763 65.7119 6.97627 63.8194 6.97627C61.9204 6.97627 60.5283 8.38121 60.5283 10.3058Z"
            fill="#1E1E1C"
          />
          <path
            d="M80.2871 6.26423L83.9952 14.3667H82.834L80.4796 9.16394C80.3 8.74694 80.146 8.40052 79.7033 7.2586C79.3377 8.23372 79.158 8.70204 78.9271 9.22809L76.6432 14.3603H75.5142L79.1773 6.25781H80.2871V6.26423Z"
            fill="#1E1E1C"
          />
          <path
            d="M98.7377 14.3602V12.6538C98.3592 13.2312 98.109 13.5006 97.692 13.7829C97.0313 14.2448 96.0882 14.5142 95.1388 14.5142C93.0666 14.5142 92.0273 13.5519 92.0273 11.6466V6.26416H93.1051V11.4156C93.1051 12.9232 93.8172 13.6032 95.389 13.6032C96.7298 13.6032 97.8909 13.0066 98.4041 12.0636C98.5709 11.7492 98.6543 11.3515 98.6543 10.8383V6.26416H99.7129V14.3667H98.7377V14.3602Z"
            fill="#1E1E1C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_11091_195865">
          <rect
            width={106}
            height={31}
            fill="white"
            transform="translate(0 0.150391)"
          />
        </clipPath>
        <clipPath id="clip1_11091_195865">
          <rect
            width={106}
            height={31}
            fill="white"
            transform="translate(0 0.150391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(TherapistLogo);
export default Memo;
