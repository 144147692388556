import { FC, memo } from 'react';
import { COLORS } from '@landing/constants';

interface Props {
  color?: string;
}

const Cross: FC<Props> = ({ color = COLORS.dark800, ...props }) => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.67946 13.087C0.933681 13.087 0.250053 13.7352 0.258931 14.5253C0.258932 15.3066 0.933682 15.9636 1.67946 15.9636L12.5554 15.9547L12.5554 26.8395C12.5554 27.603 13.2124 28.26 13.9937 28.26C14.7749 28.2423 15.4319 27.603 15.4231 26.8306L15.4319 15.9547L26.299 15.9547C27.0714 15.9636 27.7284 15.3066 27.7284 14.5253C27.7106 13.744 27.0714 13.087 26.3078 13.087L15.4231 13.087L15.4319 2.21113C15.4319 1.46535 14.7749 0.772842 13.9937 0.790599C13.2124 0.790599 12.5554 1.46535 12.5554 2.21113L12.5642 13.087L1.67946 13.087Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(Cross);
export default Memo;
