import { FC, memo } from 'react';

const Line2: FC = ({ ...props }) => {
  return (
    <svg
      width={173}
      height={9}
      viewBox="0 0 173 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.06906 7.95155C15.4416 5.61183 69.7473 1.1185 171.989 1.86299"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const Memo = memo(Line2);
export default Memo;
