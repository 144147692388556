import { ButtonHTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';
import { TYPOGRAPHY, COLORS } from '@landing/constants';

type StyleButton = 'primary' | 'outline';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: StyleButton;
  className?: string;
  isLarge?: boolean;
}

const Button: FC<Props> = ({
  styleType = 'primary',
  className,
  onClick,
  isLarge = false,
  ...props
}) => {
  return (
    <Root
      $type={styleType}
      $isLarge={isLarge}
      className={className}
      {...props}
      onClick={onClick}
    >
      <span>{props.children}</span>
    </Root>
  );
};

export default Button;

interface StyleProps {
  $type: StyleButton;
  $isLarge: boolean;
}

const Root = styled.button<StyleProps>`
  ${TYPOGRAPHY.bodySemibold16};
  border-radius: 8px;

  &:not([disabled]) {
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.42vw ${COLORS.elementsStatesFocused};
    }

    &:active {
      outline: 0;
      box-shadow: 0 0 0 0.42vw ${COLORS.elementsStatesFocused};
    }
  }

  ${({ $type }) => {
    if ($type === 'primary') {
      return css`
        background-color: ${COLORS.green};
        border-color: ${COLORS.white};
        color: ${COLORS.white};

        &:not([disabled]) {
          &:hover {
            background-color: ${COLORS.green};
          }

          &:active {
            background-color: ${COLORS.green};
          }
        }

        &[disabled] {
          background-color: ${COLORS.green};
        }
      `;
    }

    if ($type === 'outline') {
      return css`
        border: 1px solid ${COLORS.green};
        background-color: transparent;
        color: ${COLORS.green};

        &:not([disabled]) {
          &:hover {
            color: ${COLORS.elementsStatesHover};
            border-color: ${COLORS.elementsStatesHover};
          }

          &:active {
            color: ${COLORS.elementsStatesPressed};
            border-color: ${COLORS.elementsStatesPressed};
          }
        }

        &[disabled] {
          color: ${COLORS.primary300};
          border-color: ${COLORS.primary300};
        }
      `;
    }
  }};

  ${({ $isLarge }) => {
    if ($isLarge) {
      return css`
        border-radius: 20px;
        ${TYPOGRAPHY.headline4Semibold24};
      `;
    }
  }};
`;
