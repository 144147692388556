import { FC, memo } from 'react';

const BusinessLogo: FC = ({ ...props }) => {
  return (
    <svg
      width={105}
      height={36}
      viewBox="0 0 105 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11397_9477)">
        <path
          d="M14.2943 11.9268V25.8838H11.9077V14.0843H2.39299V25.8838H0V11.9268H2.39299H11.9077H14.2943Z"
          fill="#1E1E1C"
        />
        <path
          d="M14.5049 20.7284C14.5049 17.0944 16.5924 14.8159 20.5892 14.8159C24.6051 14.8159 26.6735 17.0944 26.6735 20.7284C26.6735 24.3433 24.6051 26.6027 20.5892 26.6027C16.5924 26.6027 14.5049 24.3497 14.5049 20.7284ZM24.5224 20.7284C24.5224 18.0745 23.0395 16.7698 20.5892 16.7698C18.158 16.7698 16.6751 18.0681 16.6751 20.7284C16.6751 23.3441 18.158 24.6425 20.5892 24.6425C23.0395 24.6488 24.5224 23.3505 24.5224 20.7284Z"
          fill="#1E1E1C"
        />
        <path
          d="M33.2285 20.7284C33.2285 17.0944 35.316 14.8159 39.3128 14.8159C43.3287 14.8159 45.3971 17.0944 45.3971 20.7284C45.3971 24.3433 43.3287 26.6027 39.3128 26.6027C35.316 26.6027 33.2285 24.3497 33.2285 20.7284ZM43.246 20.7284C43.246 18.0745 41.7631 16.7698 39.3128 16.7698C36.8817 16.7698 35.3988 18.0681 35.3988 20.7284C35.3988 23.3441 36.8817 24.6425 39.3128 24.6425C41.7631 24.6488 43.246 23.3505 43.246 20.7284Z"
          fill="#1E1E1C"
        />
        <path
          d="M55.8535 20.7284C55.8535 17.0944 57.941 14.8159 61.9378 14.8159C65.9537 14.8159 68.0222 17.0944 68.0222 20.7284C68.0222 24.3433 65.9537 26.6027 61.9378 26.6027C57.941 26.6027 55.8535 24.3497 55.8535 20.7284ZM65.871 20.7284C65.871 18.0745 64.3881 16.7698 61.9378 16.7698C59.5067 16.7698 58.0238 18.0681 58.0238 20.7284C58.0238 23.3441 59.5067 24.6425 61.9378 24.6425C64.3817 24.6488 65.871 23.3505 65.871 20.7284Z"
          fill="#1E1E1C"
        />
        <path
          d="M55.796 23.1091C55.796 25.0248 54.1158 26.024 51.6083 26.024H45.7021V15.3955H51.1691C53.5112 15.3955 55.185 16.3565 55.185 18.1004C55.185 19.3159 54.3258 20.4106 52.7602 20.5188C54.4213 20.6206 55.796 21.3844 55.796 23.1091ZM47.8406 19.7615H50.6409C52.0665 19.7615 53.0275 19.5578 53.0275 18.3868C53.0275 17.2157 52.0729 17.1457 50.6409 17.1457H47.8406V19.7615ZM53.5812 22.9309C53.5812 21.7344 52.6266 21.4671 51.0291 21.4671H47.8469V24.3947H51.0291C52.6202 24.3947 53.5812 24.1274 53.5812 22.9309Z"
          fill="#1E1E1C"
        />
        <path
          d="M26.9463 26.0303V15.3955H31.2549H34.0298V17.1457H29.0847V21.9572V26.0303H26.9463Z"
          fill="#1E1E1C"
        />
        <path
          d="M70.5425 24.0126V29.7723H68.2832V15.3189H69.467L70.5425 17.2154C71.4526 15.8789 72.6937 14.937 74.953 14.937C78.3898 14.937 80.6746 17.1773 80.6746 20.6204C80.6746 24.038 78.3962 26.3037 74.953 26.3037C72.6873 26.291 71.4526 25.3491 70.5425 24.0126ZM70.5425 20.1749V21.0659C70.7844 23.4971 72.6173 24.3626 74.3739 24.3626C76.6969 24.3626 78.4725 23.0261 78.4725 20.614C78.4725 18.2019 76.525 16.8463 74.3739 16.8463C72.611 16.84 70.8226 17.9792 70.5425 20.1749Z"
          fill="#1E1E1C"
        />
        <path
          d="M92.8618 15.4463V26.0302H91.4553L90.6025 23.9809C89.6733 25.4892 87.9358 26.4121 85.7719 26.4121C82.5452 26.4121 80.9414 24.3437 80.9414 21.4861V15.4399H83.1817V21.3333C83.1817 23.4208 84.2445 24.541 86.3511 24.541C88.1649 24.541 89.6924 23.6881 90.6025 21.9507V15.4463H92.8618Z"
          fill="#1E1E1C"
        />
        <path
          d="M104.954 15.4336V26.0493H102.905V18.4821L97.8581 25.7502H97.7499L92.703 18.3676V26.0493H91.5638L90.7109 23.9873V15.4336H92.913L97.839 22.6635L102.771 15.4336H104.954Z"
          fill="#1E1E1C"
        />
        <path
          d="M71.5037 33.7118C71.5037 34.9274 70.5173 35.9139 69.3017 35.9139C68.0861 35.9139 67.0996 34.9274 67.0996 33.7118C67.0996 32.4962 68.0861 31.5098 69.3017 31.5098C70.5236 31.5098 71.5037 32.4962 71.5037 33.7118Z"
          fill="#1E1E1C"
        />
        <path
          d="M97.998 5.6709H99.0482V8.97399H103.955V5.6709H105.012V13.7154H103.955V9.8841H99.0482V13.7154H97.998V5.6709Z"
          fill="#1E1E1C"
        />
        <path
          d="M67.9961 5.6709H69.0526V8.97399H73.9595V5.6709H75.016V13.7154H73.9595V9.8841H69.0526V13.7154H67.9961V5.6709Z"
          fill="#1E1E1C"
        />
        <path
          d="M91.7352 13.6328C91.2579 13.7665 91.0415 13.7983 90.7614 13.7983C90.0677 13.7983 89.7113 13.3401 89.7113 12.4299C88.6612 13.5628 87.7702 13.9447 86.1918 13.9447C84.2507 13.9447 83.0479 13.0409 83.0479 11.5899C83.0479 10.6352 83.4743 9.97968 84.3653 9.58509C84.779 9.40688 85.339 9.27323 86.37 9.13958L88.0502 8.91046C89.3167 8.73226 89.6986 8.48405 89.6986 7.80943C89.6986 6.87387 88.8585 6.37745 87.312 6.37745C85.4345 6.37745 84.5817 6.93752 84.448 8.26766H83.3724C83.5061 7.39575 83.6716 6.96934 84.0661 6.54293C84.7408 5.75375 85.7145 5.44189 87.3565 5.44189C88.8203 5.44189 89.7559 5.77284 90.2841 6.49201C90.6469 6.96934 90.7105 7.31301 90.7105 8.56679V12.1881C90.7105 12.6654 90.876 12.8945 91.2388 12.8945C91.3533 12.8945 91.4997 12.8627 91.7352 12.7927V13.6328ZM89.7113 9.91603C89.7113 9.636 89.7304 9.52144 89.7431 9.22232C89.3167 9.52144 88.9858 9.636 87.8657 9.8142L86.37 10.0433C84.6262 10.3233 84.098 10.667 84.098 11.5262C84.098 12.5 84.919 13.0537 86.3382 13.0537C87.5093 13.0537 88.5403 12.6272 89.2531 11.8381C89.5968 11.4244 89.6986 11.2144 89.6986 10.7498L89.7113 9.91603Z"
          fill="#1E1E1C"
        />
        <path
          d="M67.6778 9.7633C67.6778 12.1627 65.8513 13.9447 63.3692 13.9447C60.8362 13.9447 59.0732 12.2199 59.0732 9.73148C59.0732 7.17938 60.8489 5.43555 63.4201 5.43555C65.934 5.44191 67.6778 7.19847 67.6778 9.7633ZM60.1234 9.68693C60.1234 11.6281 61.4917 13.0091 63.4137 13.0091C65.2403 13.0091 66.6086 11.609 66.6086 9.73148C66.6086 7.77126 65.2785 6.37747 63.401 6.37747C61.5044 6.37747 60.1234 7.77763 60.1234 9.68693Z"
          fill="#1E1E1C"
        />
        <path
          d="M79.7512 5.6709L83.4362 13.7154H82.2906L79.9549 8.54758C79.7767 8.1339 79.6239 7.79023 79.1784 6.65737C78.8156 7.63112 78.6374 8.08935 78.402 8.61759L76.1299 13.7154H75.0098L78.6438 5.6709H79.7512Z"
          fill="#1E1E1C"
        />
        <path
          d="M98.0746 13.7154V12.0225C97.6991 12.5953 97.4509 12.8626 97.0372 13.1426C96.3817 13.6009 95.4398 13.8682 94.5042 13.8682C92.4485 13.8682 91.4111 12.9135 91.4111 11.0233V5.67726H92.4803V10.7942C92.4803 12.2898 93.1868 12.9644 94.7524 12.9644C96.0826 12.9644 97.2345 12.3726 97.75 11.437C97.9155 11.1251 97.9982 10.7306 97.9982 10.2214V5.6709H99.0483V13.7154H98.0746Z"
          fill="#1E1E1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_11397_9477">
          <rect width={105} height={36} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(BusinessLogo);
export default Memo;
