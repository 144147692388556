import { FC, memo } from 'react';

const ClientLogo: FC = ({ ...props }) => {
  return (
    <svg
      width={99}
      height={30}
      viewBox="0 0 99 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_381_268465)">
        <path
          d="M11.0859 11.2341H2.22438H0V24.3015H2.22438V13.2482H11.0859V24.3015H13.3103V11.2341H11.0859Z"
          fill="#001F1B"
        />
        <path
          d="M13.5083 19.4773C13.5083 16.0702 15.4509 13.9355 19.1742 13.9355C22.9095 13.9355 24.8341 16.0702 24.8341 19.4773C24.8341 22.8662 22.9095 24.9768 19.1742 24.9768C15.4509 24.9768 13.5083 22.8602 13.5083 19.4773ZM22.8315 19.4773C22.8315 16.9868 21.4525 15.7747 19.1682 15.7747C16.9018 15.7747 15.5228 16.9928 15.5228 19.4773C15.5228 21.9315 16.9018 23.1436 19.1682 23.1436C21.4465 23.1436 22.8315 21.9255 22.8315 19.4773Z"
          fill="#001F1B"
        />
        <path
          d="M30.9375 19.4773C30.9375 16.0702 32.8801 13.9355 36.5974 13.9355C40.3327 13.9355 42.2573 16.0702 42.2573 19.4773C42.2573 22.8662 40.3327 24.9768 36.5974 24.9768C32.8801 24.9768 30.9375 22.8602 30.9375 19.4773ZM40.2607 19.4773C40.2607 16.9868 38.8817 15.7747 36.5974 15.7747C34.331 15.7747 32.952 16.9928 32.952 19.4773C32.952 21.9315 34.337 23.1436 36.5974 23.1436C38.8757 23.1436 40.2607 21.9255 40.2607 19.4773Z"
          fill="#001F1B"
        />
        <path
          d="M52 19.4773C52 16.0702 53.9426 13.9355 57.6659 13.9355C61.4012 13.9355 63.3258 16.0702 63.3258 19.4773C63.3258 22.8662 61.4012 24.9768 57.6659 24.9768C53.9486 24.9768 52 22.8602 52 19.4773ZM61.3232 19.4773C61.3232 16.9868 59.9442 15.7747 57.6599 15.7747C55.3935 15.7747 54.0145 16.9928 54.0145 19.4773C54.0145 21.9315 55.3935 23.1436 57.6599 23.1436C59.9442 23.1436 61.3232 21.9255 61.3232 19.4773Z"
          fill="#001F1B"
        />
        <path
          d="M51.9464 21.7024C51.9464 23.4933 50.3816 24.4341 48.0493 24.4341H42.5513V14.4783H47.6416C49.818 14.4783 51.3828 15.3768 51.3828 17.0109C51.3828 18.1506 50.5854 19.1758 49.1285 19.2722C50.6634 19.3748 51.9464 20.0863 51.9464 21.7024ZM44.5418 18.5667H47.1499C48.481 18.5667 49.3683 18.3737 49.3683 17.2823C49.3683 16.1908 48.481 16.1185 47.1499 16.1185H44.5418V18.5667ZM49.8839 21.5335C49.8839 20.4119 48.9906 20.1647 47.5097 20.1647H44.5478V22.9024H47.5097C48.9906 22.9024 49.8839 22.6552 49.8839 21.5335Z"
          fill="#001F1B"
        />
        <path
          d="M25.0859 24.4341V14.4783H29.103H31.6811V16.1185H27.0825V20.623V24.4341H25.0859Z"
          fill="#001F1B"
        />
        <path
          d="M65.6762 22.5527V27.9437H63.5718V14.406H64.675L65.6762 16.1789C66.5216 14.9306 67.6788 14.0442 69.7833 14.0442C72.9849 14.0442 75.1074 16.1427 75.1074 19.3628C75.1074 22.5648 72.9849 24.6814 69.7833 24.6814C67.6788 24.6874 66.5216 23.801 65.6762 22.5527ZM65.6762 18.9527V19.7909C65.9041 22.0703 67.6068 22.8784 69.2437 22.8784C71.4021 22.8784 73.0569 21.6301 73.0569 19.3688C73.0569 17.1075 71.2462 15.8412 69.2437 15.8412C67.6068 15.8351 65.9341 16.8965 65.6762 18.9527Z"
          fill="#001F1B"
        />
        <path
          d="M86.4573 14.5266V24.4402H85.1443L84.3528 22.5226C83.4895 23.9336 81.8706 24.802 79.8561 24.802C76.8523 24.802 75.3594 22.8663 75.3594 20.1889V14.5266H77.4459V20.0442C77.4459 21.998 78.4351 23.0472 80.3957 23.0472C82.0865 23.0472 83.5074 22.2512 84.3528 20.6231V14.5266H86.4573Z"
          fill="#001F1B"
        />
        <path
          d="M97.7168 14.5144V24.4521H95.8102V17.3667L91.1096 24.1747H91.0077L86.3071 17.2581V24.4521H85.2519L84.4604 22.5285V14.5144H86.505L91.0916 21.2802L95.6783 14.5144H97.7168Z"
          fill="#001F1B"
        />
        <path
          d="M93.2382 27.9437C93.2382 29.0834 92.3209 30.006 91.1877 30.006C90.0545 30.006 89.1372 29.0834 89.1372 27.9437C89.1372 26.804 90.0545 25.8813 91.1877 25.8813C92.3209 25.8813 93.2382 26.804 93.2382 27.9437Z"
          fill="#B8362D"
        />
        <path
          d="M98.9999 27.9437C98.9999 29.0834 98.0826 30.006 96.9494 30.006C95.8163 30.006 94.8989 29.0834 94.8989 27.9437C94.8989 26.804 95.8163 25.8813 96.9494 25.8813C98.0826 25.8813 98.9999 26.804 98.9999 27.9437Z"
          fill="#001F1B"
        />
        <path
          d="M87.4702 27.9437C87.4702 29.0834 86.5528 30.006 85.4196 30.006C84.2865 30.006 83.3691 29.0834 83.3691 27.9437C83.3691 26.804 84.2865 25.8813 85.4196 25.8813C86.5528 25.8813 87.4702 26.804 87.4702 27.9437Z"
          fill="#64AB8A"
        />
        <path
          d="M91.2358 5.26416H92.2071V8.33954H96.7458V5.26416H97.7171V12.7355H96.7458V9.18376H92.2071V12.7355H91.2358V5.26416Z"
          fill="#001F1B"
        />
        <path
          d="M63.5298 5.26416H64.5011V8.33954H69.0398V5.26416H70.0111V12.7355H69.0398V9.18376H64.5011V12.7355H63.5298V5.26416Z"
          fill="#001F1B"
        />
        <path
          d="M85.4558 12.6633C85.0121 12.7839 84.8202 12.8141 84.5564 12.8141C83.9209 12.8141 83.5851 12.386 83.5851 11.5478C82.6138 12.603 81.7924 12.9528 80.3355 12.9528C78.5428 12.9528 77.4336 12.1146 77.4336 10.7699C77.4336 9.88345 77.8293 9.2744 78.6507 8.90656C79.0284 8.73772 79.5501 8.61711 80.5034 8.49651L82.0562 8.28546C83.2254 8.11661 83.5731 7.88747 83.5731 7.26033C83.5731 6.39199 82.7997 5.92767 81.3667 5.92767C79.634 5.92767 78.8426 6.44626 78.7227 7.68847H77.7334C77.8533 6.88043 78.0092 6.48244 78.3689 6.08445C78.9925 5.34877 79.8858 5.05933 81.4087 5.05933C82.7637 5.05933 83.6271 5.36686 84.1127 6.03621C84.4485 6.48244 84.5084 6.80204 84.5084 7.95983V11.3247C84.5084 11.7709 84.6583 11.9819 84.9941 11.9819C85.102 11.9819 85.2399 11.9518 85.4498 11.8915V12.6633H85.4558ZM83.5851 9.20807C83.5851 8.94877 83.6031 8.84023 83.6151 8.56887C83.2194 8.84626 82.9136 8.94877 81.8824 9.11762L80.4974 9.32867C78.8845 9.58797 78.3989 9.90757 78.3989 10.7035C78.3989 11.6081 79.1603 12.1267 80.4674 12.1267C81.5466 12.1267 82.5059 11.7287 83.1594 10.993C83.4772 10.6131 83.5671 10.4141 83.5671 9.98596L83.5851 9.20807Z"
          fill="#001F1B"
        />
        <path
          d="M63.2359 9.06923C63.2359 11.3004 61.5511 12.9526 59.2548 12.9526C56.9165 12.9526 55.2856 11.3486 55.2856 9.03908C55.2856 6.66923 56.9285 5.04712 59.2967 5.04712C61.623 5.05315 63.2359 6.68732 63.2359 9.06923ZM56.2569 8.99687C56.2569 10.7999 57.516 12.0843 59.2967 12.0843C60.9815 12.0843 62.2466 10.7878 62.2466 9.04511C62.2466 7.224 61.0175 5.92752 59.2847 5.92752C57.534 5.92149 56.2569 7.224 56.2569 8.99687Z"
          fill="#001F1B"
        />
        <path
          d="M74.3879 5.26416L77.7934 12.7415H76.7322L74.5738 7.94155C74.4059 7.56165 74.268 7.23602 73.8603 6.18074C73.5245 7.08527 73.3567 7.51341 73.1468 8.00185L71.0483 12.7415H70.0171L73.3746 5.26416H74.3879Z"
          fill="#001F1B"
        />
        <path
          d="M91.3138 12.7417V11.1678C90.966 11.7045 90.7382 11.9457 90.3545 12.205C89.7489 12.6332 88.8795 12.8804 88.0162 12.8804C86.1155 12.8804 85.1562 11.994 85.1562 10.2332V5.26433H86.1455V10.0161C86.1455 11.4091 86.7991 12.0362 88.244 12.0362C89.4731 12.0362 90.5403 11.4874 91.008 10.6131C91.1579 10.3236 91.2358 9.95579 91.2358 9.47941V5.2583H92.2071V12.7357H91.3138V12.7417Z"
          fill="#001F1B"
        />
      </g>
    </svg>
  );
};

const Memo = memo(ClientLogo);
export default Memo;
