import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';

interface Props {
  className?: string;
  children?: ReactNode;
}

const Container: FC<Props> = ({ className, children }) => {
  return <Root className={className}>{children}</Root>;
};

const Root = styled.div`
  width: 100%;
  max-width: calc(${apx.d(1442)} - ${apx.d(66)});
  margin: 0 auto;
  padding-left: ${apx.d(33)};
  padding-right: ${apx.d(33)};
  position: relative;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-left: ${apx.l(32)};
    padding-right: ${apx.l(32)};
    max-width: none;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-left: ${apx.m(25)};
    padding-right: ${apx.m(25)};
  }
`;

export default Container;
