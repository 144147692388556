import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#ECF7F6"
        d="M0 10C0 4.477 4.477 0 10 0h20c5.523 0 10 4.477 10 10v20c0 5.523-4.477 10-10 10H10C4.477 40 0 35.523 0 30V10Z"
      />
      <path
        fill="#3DAE9F"
        d="M29.584 15.153a2.508 2.508 0 0 0-1.768-1.78c-1.56-.421-7.813-.421-7.813-.421s-6.256 0-7.813.421a2.508 2.508 0 0 0-1.768 1.78C10 16.724 10 20 10 20s0 3.277.419 4.846a2.508 2.508 0 0 0 1.768 1.78c1.56.422 7.813.422 7.813.422s6.256 0 7.813-.422a2.508 2.508 0 0 0 1.768-1.78C30 23.277 30 20 30 20s0-3.277-.416-4.846Zm-11.63 7.82v-5.946L23.182 20l-5.226 2.973Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
const YouTube = memo(SvgComponent);
export default YouTube;
