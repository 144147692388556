import apx from './adaptivepx';

export const TYPOGRAPHY = {
  heading1Bold72: `
    font-weight: 700;
    font-size: ${apx.d(72)};
    line-height: ${apx.d(89)};
  `,
  heading1SemiBold72: `
    font-weight: 600;
    font-size: ${apx.d(72)};
    line-height: ${apx.d(86)};
  `,
  heading2SemiBold64: `
    font-weight: 600;
    font-size: ${apx.d(64)};
    line-height: ${apx.d(83)};
  `,
  heading3Medium64: `
    font-weight: 500;
    font-size: ${apx.d(64)};
    line-height: ${apx.d(83)};
  `,
  heading3Regular64: `
    font-weight: 400;
    font-size: ${apx.d(64)};
    line-height: ${apx.d(83)};
  `,
  heading5Semibold18: `
    font-weight: 600;
    font-size: ${apx.d(18)};
    line-height: ${apx.d(24)};
  `,
  heading6Light48: `
    font-weight: 300;
    font-size: ${apx.d(48)};
    line-height: 130%;
  `,
  headline1Semibold40: `
    font-weight: 600;
    font-size: ${apx.d(40)};
    line-height: ${apx.d(52)};
  `,
  headline2Semibold36: `
    font-weight: 600;
    font-size: ${apx.d(36)};
    line-height: ${apx.d(44)};
  `,
  headline2Regular36: `
    font-weight: 400;
    font-size: ${apx.d(36)};
    line-height: ${apx.d(44)};
  `,
  headline3Bold24: `
    font-weight: 700;
    font-size: ${apx.d(24)};
    line-height: ${apx.d(29)};
  `,
  headline4Semibold24: `
    font-weight: 600;
    font-size: ${apx.d(24)};
    line-height: ${apx.d(31)};
  `,
  headline5Regular24: `
    font-weight: 400;
    font-size: ${apx.d(24)};
    line-height: ${apx.d(31)};
  `,
  bodyBold48: `
    font-weight: 700;
    font-size: ${apx.d(48)};
    lin-height: ${apx.d(62)};
  `,
  bodyMedium32: `
    font-weight: 500;
    font-size: ${apx.d(32)};
    line-height: ${apx.d(42)};
  `,
  bodySemiBold24: `
    font-weight: 600;
    font-size: ${apx.d(24)};
    line-height: ${apx.d(29)};
  `,
  bodyMedium24: `
    font-weight: 500;
    font-size: ${apx.d(24)};
    line-height: ${apx.d(31)};
  `,
  bodySemibold20: `
    font-weight: 600;
    font-size: ${apx.d(20)};
    line-height: ${apx.d(26)};
  `,
  bodyMedium20: `
    font-weight: 500;
    font-size: ${apx.d(20)};
    line-height: ${apx.d(22)};
  `,
  bodyMedium18: `
    font-weight: 500;
    font-size: ${apx.d(18)};
    line-height: ${apx.d(25)};
  `,
  bodyLight28: `
    font-weight: 300;
    font-size: ${apx.d(28)};
    line-height: 130%;
  `,
  bodyBold16: `
    font-weight: 700;
    font-size: ${apx.d(16)};
    line-height: ${apx.d(20)};
  `,
  bodyBold36: `
    font-weight: 700;
    font-size: ${apx.d(36)};
    line-height: ${apx.d(29)};
  `,
  bodySemibold16: `
    font-weight: 600;
    font-size: ${apx.d(16)};
    line-height: ${apx.d(21)};
  `,
  bodyBold26: `
    font-weight: 700;
    font-size: ${apx.d(26)};
    line-height: ${apx.d(32)};
  `,
  bodyBold22: `
    font-weight: 700;
    font-size: ${apx.d(22)};
    line-height: ${apx.d(28)};
  `,
  bodySemibold162: `
    font-weight: 600;
    font-size: ${apx.d(16)};
    line-height: ${apx.d(19)};
  `,
  bodyMedium16: `
    font-weight: 500;
    font-size: ${apx.d(16)};
    line-height: ${apx.d(21)};
  `,
  bodyRegular16: `
    font-weight: 400;
    font-size: ${apx.d(16)};
    line-height: ${apx.d(21)};
  `,
  bodyMedium14: `
    font-weight: 500;
    font-size: ${apx.d(14)};
    line-height: ${apx.d(20)};
  `,
  bodyMedium12: `
    font-weight: 500;
    font-size: ${apx.d(12)};
    line-height: ${apx.d(20)};
  `,
  bodyRegular14: `
    font-weight: 400;
    font-size: ${apx.d(14)};
    line-height: ${apx.d(20)};
  `,
  bodyRegular20: `
    font-weight: 400;
    font-size: ${apx.d(20)};
    line-height: ${apx.d(26)};
  `,
  bodyRegular12: `
    font-weight: 400;
    font-size: ${apx.d(12)};
    line-height: 130%;
  `,
  bodyRegular13: `
    font-weight: 400;
    font-size: ${apx.d(13)};
    line-height: 130%;
  `,
  bodySemibold11: `
    font-weight: 600;
    font-size: ${apx.d(11)};
    line-height: ${apx.d(14)};
  `,
  bodyRegular11: `
    font-weight: 400;
    font-size: ${apx.d(11)};
    line-height: ${apx.d(28)};
  `,
  bodyMedium10: `
    font-weight: 500;
    font-size: ${apx.d(10)};
    line-height: ${apx.d(16)};
  `,
  footnoteRegular16: `
    font-weight: 400;
    font-size: ${apx.d(16)};
    line-height: ${apx.d(30)};
  `,
  footnoteRegular12: `
    font-weight: 400;
    font-size: ${apx.d(12)};
    line-height: ${apx.d(30)};
  `,
  footnoteRegular13: `
    font-weight: 400;
    font-size: ${apx.d(13)};
    line-height: ${apx.d(20)};
  `,
  footnoteMedium13: `
    font-weight: 500;
    font-size: ${apx.d(13)};
    line-height: ${apx.d(20)};
  `,
  bodyMedium8: `
    font-weight: 500;
    font-size: ${apx.d(8)};
    line-height: ${apx.d(11)};
  `,
  heading5Semibold48: `
    font-weight: 600;
    font-size: ${apx.d(48)};
    line-height: 130%;
  `,

  // askona
  askona: {
    b4Semibold24: `
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 600;
      font-size: ${apx.d(24)};
      line-height: ${apx.d(31)};
  `,
    b3Semibold28: `
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 600;
      font-size: ${apx.d(28)};
      line-height: ${apx.d(36)};
    `,
    b13Medium13: `
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: ${apx.d(13)};
      line-height: ${apx.d(20)};
  `,
    b10Medium16: `
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: ${apx.d(16)};
      line-height: ${apx.d(21)};
    `,
    b7Medium20: `
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: ${apx.d(20)};
      line-height: ${apx.d(22)};
    `,
  },
};
