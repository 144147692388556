import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={40}
    fill="none"
    {...props}
  >
    <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path fill="#92CFAE" d="M0 0v37.269L37.478 0H0Z" />
      <path fill="#2A8288" d="M2.646 40h37.566V2.643L2.646 40Z" />
      <path
        fill="#222"
        d="M61.288 19.119c4.32 0 6.702-2.115 6.702-6.08v-.264c0-4.229-2.47-5.903-6.702-5.903H56.79V19.12h4.498ZM47.884 0h13.668c9.612 0 15.08 4.317 15.08 12.775v.265c0 8.458-5.82 12.423-14.815 12.423H56.79V40h-8.906V0Zm46.384 33.128c4.32 0 6.437-1.939 6.437-5.55v-.265c0-3.613-2.204-5.55-6.702-5.55h-4.938V33.04h5.203v.088ZM94.532 40H80.335V0h25.22v7.048h-16.49l-.088 8.37h5.82c8.907 0 14.286 3.965 14.286 11.983v.264c0 7.754-4.939 12.335-14.55 12.335ZM115.522.088h9.347v17.18L139.242 0h9.965l-16.314 18.943L150 40h-10.935l-14.197-17.974V40h-9.347V.088Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h150v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
const RBK = memo(SvgComponent);
export default RBK;
